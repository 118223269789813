<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button />
        </ion-buttons>
        <ion-title>{{ $route.name }}</ion-title>
        <ion-button
          @click="goToHome()"
          color="primary"
          fill="clear"
          slot="end"
          class="ion-no-padding ion-margin-end"
        >
          <ion-icon icon="home" />
        </ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-tabs>
      <ion-tab-bar>
        <ion-tab-button tab="main" href="/main/home">
          <ion-icon icon="home" />
        </ion-tab-button>

        <ion-tab-button tab="aree-tematiche" href="/main/aree-tematiche">
          <ion-icon icon="layers" />
        </ion-tab-button>

        <ion-tab-button tab="real-time" href="/main/real-time">
          <ion-icon icon="timer" />
        </ion-tab-button>

        <ion-tab-button tab="banca-dati" href="/main/banca-dati">
          <ion-icon icon="library" />
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script lang="ts">
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonIcon,
  IonPage,
  IonButtons,
  IonTitle,
  IonHeader,
  IonToolbar,
  IonMenuButton,
  IonButton,
} from '@ionic/vue';
import { addIcons } from 'ionicons';
import { ref, onMounted, defineComponent } from 'vue';
import { useCookie } from '@vue-composable/cookie';
import { useRouter } from 'vue-router';
import {
  search,
  home,
  timer,
  library,
  layers,
  logIn,
  logOut,
} from 'ionicons/icons';
addIcons({
  home,
  search,
  timer,
  library,
  layers,
  'log-in': logIn,
  'log-out': logOut,
});
import * as eventBus from '@/services/event.bus.js';

export default defineComponent({
  name: 'JnMainTabs',
  components: {
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonButtons,
    IonTitle,
    IonHeader,
    IonToolbar,
    IonMenuButton,
    IonButton,
  },
  setup() {
    const isAuthenticated = ref(false);
    const router = useRouter();

    function goToHome() {
      location.href = '/main/home';
    }

    onMounted(async () => {
      eventBus.default.$on('login', function(authenticated: boolean) {
        isAuthenticated.value = authenticated;
      });
      eventBus.default.$on('logout', function(authenticated: boolean) {
        isAuthenticated.value = authenticated;
      });
      const { cookie } = useCookie('username');
      if (cookie.value) {
        isAuthenticated.value = true;
      } else {
        isAuthenticated.value = false;
      }
    });

    return {
      isAuthenticated,
      goToHome,
    };
  },
});
</script>
